export enum AIBlockType {
  GPT35 = 'GPT3.5',
  GPT16K = 'GPT3.5 16K',
  Spark = '讯飞星火',
  Ernie4 = '文心一言',
  GPTTurbo = 'GPT4 Turbo',
  GPT4 = 'GPT4',
  GPTVision = 'GPT Vision',
  StableDiffusionXL = 'StableDiffusion XL',
  MidJourney = 'MidJourney',
  Claude = 'Claude3',
  NewBing = 'NewBing'
}
