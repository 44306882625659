
import { MainNavItem} from "types/nav"

interface MobileConfig {
    mainNav: MainNavItem[]
}

export const mobileConfig: MobileConfig = {
    mainNav: [
        {
            title: ('私有部署'),
            href: "/",
        }
    ]
}
