'use client'
import {signOut, useSession} from 'next-auth/react'
import {useRouter,usePathname} from 'next/navigation'
import {createContext, ReactNode, useEffect, useState} from 'react'
import {isDefined, isNotDefined} from '@halfwork.cn/lib'
import {User} from '@halfwork.cn/prisma'
import {setUser as setSentryUser} from '@sentry/nextjs'
import {updateUserQuery} from './queries/updateUserQuery'
import {useDebouncedCallback} from 'use-debounce'
import Debugger from "@/utils/debugger";

export const userContext = createContext<{
    user?: User
    isLoading: boolean
    currentWorkspaceId?: string
    logOut: () => void
    updateUser: (newUser: Partial<User>) => void
}>({
    isLoading: false,
    logOut: () => {
        Debugger.log('logOut not implemented')
    },
    updateUser: () => {
        Debugger.log('updateUser not implemented')
    },
})

const debounceTimeout = 1000
const signPages = ['/dashboard/api-settings', '/dashboard/users', '/dashboard/agent','/dashboard/config','/dashboard/finance','/dashboard/campus',
'/dashboard/college','/dashboard/coursewares','/account','/member','/studio','/template-studio','/cost','/dashboard/notification',
]

type UserProviderProps = {
    children: ReactNode
    workspaceId: string
}

export const UserProvider = ({children, workspaceId}: UserProviderProps) => {
    const router = useRouter();
    const pathName = usePathname();
    const {data: session, status} = useSession()
    const [user, setUser] = useState<User | undefined>()
    const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>()

    useEffect(() => {
        if (isDefined(user) || isNotDefined(session)) return
        const parsedUser = session.user as User
        setUser(parsedUser)
        if (parsedUser?.id) {
            setSentryUser({id: parsedUser.id})
        }
    }, [session, user])

    useEffect(() => {
        if (status === 'loading') return
        const isSigningIn = () => pathName && signPages.includes(pathName.replace(`/${workspaceId}`,''));
        if (!user && status === 'unauthenticated' && isSigningIn())
            router.replace(`/${workspaceId}/login?redirect=${pathName}`)
    }, [router, status, user])

    const updateUser = (updates: Partial<User>) => {
        if (isNotDefined(user)) return
        const newUser = {...user, ...updates}
        setUser(newUser);
        saveUser(newUser);
    }

    const saveUser = useDebouncedCallback(
        async (newUser?: Partial<User>) => {
            if (isNotDefined(user)) return
            const {error} = await updateUserQuery(user.id, {...user, ...newUser})
            await refreshUser()
        }, debounceTimeout
    )

    const logOut = () => {
        signOut()
        setUser(undefined)
    }

    useEffect(() => {
        return () => {
            saveUser.flush()
        }
    }, [saveUser])

    return (
        <userContext.Provider
            value={{
                user,
                isLoading: status === 'loading',
                currentWorkspaceId,
                logOut,
                updateUser,
            }}
        >
            {children}
        </userContext.Provider>
    )
}

export const refreshUser = async () => {
    await fetch('/api/auth/session?update')
    reloadSession()
}

const reloadSession = () => {
    const event = new Event('visibilitychange')
    document.dispatchEvent(event)
}
