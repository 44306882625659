'use client'
import * as React from 'react'
import {CheckIcon, MoonIcon, ResetIcon, SunIcon} from '@radix-ui/react-icons'
import {useTheme} from 'next-themes'
import {Button} from '@/registry/new-york/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/registry/new-york/ui/dropdown-menu'
import {useConfig} from '@/hooks/use-config'
import {ThemeWrapper} from '@/components/theme-wrapper'
import {Label} from '@/registry/new-york/ui/label'
import {cn} from '@/lib/utils'
import {themes} from '@/registry/themes'
import {Skeleton} from '@/registry/new-york/ui/skeleton'
import {useTranslations} from 'next-intl'
import {useLocale} from 'next-intl'
import {useRouter} from 'next/navigation'

interface ModeToggleProps {
    fullScreen?: boolean;
    textSecond?: boolean
}

export function ModeToggle({textSecond, fullScreen}:ModeToggleProps) {
    const [mounted, setMounted] = React.useState(false)
    React.useEffect(() => {
        setMounted(true)
    }, [])
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant='ghost'
                        className={cn('w-9 px-0 ', textSecond && !fullScreen ? ' text-background dark:text-foreground' : 'text-foreground dark:text-background')}>
                    <SunIcon
                        className='h-[1.4rem] w-[1.4rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0'/>
                    <MoonIcon
                        className='absolute h-[1.4rem] w-[1.4rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100'/>
                    <span className='sr-only'>Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'
                                 className='  bg-card w-[340px] rounded-[0.5rem] backdrop-blur bg-white p-6 dark:bg-card'>
                <Customizer/>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

function Customizer() {
    const t = useTranslations('components.mode-toggle')
    const [mounted, setMounted] = React.useState(false)
    const {setTheme: setMode, resolvedTheme: mode} = useTheme()
    const [config, setConfig] = useConfig()
    const locale = useLocale()
    const router = useRouter()
    React.useEffect(() => {
        setMounted(true)
    }, [])

    return (
        <div
            className='flex flex-col space-y-4 md:space-y-4'
        >
            <div className='flex items-start'>
                <div className='space-y-3 pr-2'>
                    <div className='font-semibold leading-none tracking-tight'>
                        {t('theme-customization')}
                    </div>
                    <div className='text-xs text-muted-foreground'>
                        {t('theme-description')}
                    </div>
                </div>
                <Button
                    variant='ghost'
                    size='icon'
                    className='ml-auto rounded-[0.5rem]'
                    onClick={() => {
                        setConfig({
                            ...config,
                            theme: 'stone',
                            radius: 0.5,
                        })
                    }}
                >
                    <ResetIcon/>
                    <span className='sr-only'>{t('reset')}</span>
                </Button>
            </div>
            <div className='flex flex-1 flex-col space-y-4 md:space-y-4'>
                <div className='space-y-1.5'>
                    <Label className='text-xs'>{t('theme-color')}</Label>
                    <div className='grid grid-cols-3 gap-2'>
                        {themes.map((theme) => {
                            const isActive = config.theme === theme.name

                            return mounted ? (
                                <Button
                                    variant={'outline'}
                                    size='sm'
                                    key={theme.name}
                                    onClick={() => {
                                        setConfig({
                                            ...config,
                                            theme: theme.name,
                                        })
                                    }}
                                    className={cn(
                                        'justify-start',
                                        isActive && 'border border-primary/70',
                                    )}
                                    style={
                                        {
                                            '--theme-primary': `hsl(${
                                                theme?.activeColor[mode === 'dark' ? 'dark' : 'light']
                                            })`,
                                        } as React.CSSProperties
                                    }
                                >
                  <span
                      className={cn(
                          'mr-1 flex h-5 w-5 shrink-0 -translate-x-1 items-center justify-center rounded-full bg-[--theme-primary]',
                      )}
                  >
                    {isActive && <CheckIcon className='h-4 w-4 text-white'/>}
                  </span>
                                    {t(theme.label)}
                                </Button>
                            ) : (
                                <Skeleton className='h-8 w-full' key={theme.name}/>
                            )
                        })}
                    </div>
                </div>
                <div className='space-y-1.5'>
                    <Label className='text-xs'>{t('border-radius')}</Label>
                    <div className='grid grid-cols-5 gap-2'>
                        {['0', '0.3', '0.5', '0.75', '1.0'].map((value) => {
                            return (
                                <Button
                                    variant={'outline'}
                                    size='sm'
                                    key={value}
                                    onClick={() => {
                                        setConfig({
                                            ...config,
                                            radius: parseFloat(value),
                                        })
                                    }}
                                    className={cn(
                                        config.radius === parseFloat(value) &&
                                        'border border-primary',
                                    )}
                                >
                                    {value}
                                </Button>
                            )
                        })}
                    </div>
                </div>
                <div className='space-y-1.5'>
                    <Label className="text-xs">语言设置</Label>
                    <div className='grid grid-cols-3 gap-2'>
                        <Button
                            variant={'outline'}
                            size='sm'
                            onClick={() => {
                                document.cookie = `local-lang=${JSON.stringify('zh')}`
                                location.reload();
                            }}
                            className={cn(`${locale}` === 'zh' && 'border border-primary/70')}
                        >
                            中文
                        </Button>
                        <Button
                            variant={'outline'}
                            size='sm'
                            onClick={() => {
                                document.cookie = `local-lang=${JSON.stringify('en')}`
                                location.reload();
                            }}
                            className={cn(locale === 'en' && 'border-2 border-primary/70')}
                        >
                            ENGLISH
                        </Button>
                    </div>
                </div>
                <div className="space-y-1.5">
                    <Label className="text-xs">{t('day-night-mode')}</Label>
                    <div className="grid grid-cols-3 gap-2">
                        {mounted ? (
                            <>
                                <Button
                                    variant={"outline"}
                                    size="sm"
                                    onClick={() => setMode("light")}
                                    className={cn(mode === "light" && "border border-primary/70")}
                                >
                                    <SunIcon className="mr-1 -translate-x-1"/>
                                    {t('light-mode')}
                                </Button>
                                <Button
                                    variant={"outline"}
                                    size="sm"
                                    onClick={() => setMode("dark")}
                                    className={cn(mode === "dark" && "border border-primary/70")}
                                >
                                    <MoonIcon className="mr-1 -translate-x-1"/>
                                    {t('dark-mode')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Skeleton className="h-8 w-full"/>
                                <Skeleton className="h-8 w-full"/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
