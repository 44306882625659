export enum InputBlockType {
    TEXT = 'text input',
    NUMBER = 'number input',
    EMAIL = 'email input',
    URL = 'url input',
    DATE = 'date input',
    PHONE = 'phone number input',
    CHOICE = 'choice input',
    PICTURE_CHOICE = 'picture choice input',
    PAYMENT = 'payment input',
    RATING = 'rating input',
    FILE = 'file input',
    FORM = 'form input'
}
