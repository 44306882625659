export enum IntegrationBlockType {
    OPEN_AI = 'OpenAI',
    GOOGLE_ANALYTICS = 'Google Analytics',
    WEBHOOK = 'Webhook',
    WEBSOCKET = 'WebSocket',
    GRAPHQL = 'GraphQL',
    EMAIL = '邮件',
    FEISHU_SHEETS = '飞书',
    WeChatMP = '微信公众号',
    DOUYIN = '抖音',
    WECHAT = '微信小程序',
    ALIPAY = '支付宝',
    RED_BOOK = '小红书',
}
