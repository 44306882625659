import {useAtom} from "jotai"
import {atomWithStorage} from "jotai/utils"

import {Style} from "@/registry/styles"
import {Theme} from "@/registry/themes"

type Config = {
    style: Style["name"]
    theme: Theme["name"]
    radius: number
    payYear: boolean
}

const configAtom = atomWithStorage<Config>("config", {
    style: "default",
    theme: "green",
    radius: 0,
    payYear: true
})

export function useConfig() {
    return useAtom(configAtom)
}
