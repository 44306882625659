
export const siteConfig = {
  name: "HALF.view",
  url: "https://halfview.cn",
  description: '服务于自由职业者和初创公司的 AI 工作流平台。',
  links: {
    twitter: "https://twitter.com/dafeng",
    github: "",
  },
}

export type SiteConfig = typeof siteConfig
