import {useAtom} from "jotai"
import {atomWithStorage} from "jotai/utils"
import {AudioLines, CaptionsOff, Clapperboard, Disc3, ImagePlay, KeyboardMusic, Languages} from "lucide-react";

export enum EditorType {
    LIST = 'list',
    TEXT = 'text',
    ICON ='icon',
    AUDIO = 'audio',
    VIDEO = 'video',
    EFFECT = 'effect',
    TRANS = 'trans',
    SHAPES = 'shapes',
    SUBTITLE = 'subtitle',
    D3 = '3d',
    LOTTIE = 'lottie',
    MATH = 'math',
    CHART = 'chart',
}

type Config = {
    type: EditorType
    aiType: AIType
    baseTabIndex: string
    resFilter: string
    resType: string
    copilot: boolean
    shortcuts: boolean
}

export enum AIType {
    audioSubtitle = 'audioSubtitle',
    subtitleAudio = 'subtitleAudio',
    subtitle = 'subtitle',
    subtitleTrans = 'subtitleTrans',
    separation = 'separation',
    aiVideoTrans = 'aiVideoTrans',
    aiAudioTrans = 'aiAudioTrans',
    aiRemoveSubtitle = 'aiRemoveSubtitle',
    video = 'video',
    image = 'image',
    music = 'music',
}

const configAtom = atomWithStorage<Config>("editor_config", {
    type: EditorType.LIST,
    aiType: AIType.audioSubtitle,
    baseTabIndex: 'video',
    resFilter: 'all',
    resType: 'pack',
    copilot: false,
    shortcuts: false,
})

export function useEditorConfig() {
    return useAtom(configAtom)
}
